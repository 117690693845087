import { z } from 'zod';

export const OauthLoginActionBodySchema = z.object({
  accessToken: z.string().optional(),
  accessTokenType: z.string().optional(),
  oauthUuid: z.string().optional(),
  userName: z.string().optional(),
  zipCode: z.string().optional(),
  gender: z.string().optional(),
  birthYear: z.string().optional(),
  type: z.literal('checkAccount').or(z.literal('createUser')),
  skipCheck: z.boolean().optional(),
});
export type OauthLoginActionBody = z.infer<typeof OauthLoginActionBodySchema>;

export const OauthLinkActionBodySchema = z.object({
  accessToken: z.string().optional(),
  accessTokenType: z.string().optional(),
  oauthUuid: z.string().optional(),
  intent: z.literal('link').or(z.literal('unlink')),
});

export type OauthLinkActionBody = z.infer<typeof OauthLinkActionBodySchema>;

export type OauthLinkForm = OauthLinkActionBody;
export type OauthLinkActionErrors = Omit<
  OauthLinkActionBody,
  'type' | 'intent'
> & {
  other?: Array<string>;
};

export type FbMeResponse = {
  email?: string;
};

export type OauthLoginForm =
  | {
      type: 'checkAccount';
      userName?: string;
      accessToken?: string;
      accessTokenType?: string;
      oauthUuid?: string;
      redirect_uri?: string;
      redirectUrl: string;
      errors?: OauthLoginActionErrors;
      skipCheck?: boolean;
      fpHash: string;
    }
  | {
      type: 'createUser';
      accessToken?: string;
      accessTokenType?: string;
      oauthUuid?: string;
      birthYear?: string;
      gender?: string;
      redirectUrl: string;
      userName?: string;
      zipCode?: string;
      errors?: OauthLoginActionErrors;
      fpHash: string;
    };

export type OauthLoginActionErrors = {
  userName?: string;
  zipCode?: string;
  birthYear?: string;
  gender?: string;
  other?: Array<string>;
};

export const IdResponseSchema = z.object({
  at_hash: z.string(),
  aud: z.string(),
  azp: z.string(),
  email: z.string(),
  email_verified: z.boolean(),
  exp: z.number(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  hd: z.string().optional(),
  iat: z.number(),
  iss: z.string(),
  locale: z.string().optional(),
  name: z.string().optional(),
  picture: z.string().optional(),
  sub: z.string(),
});

export type IdResponse = z.infer<typeof IdResponseSchema>;

export const RequestCodeResponseSchema = z.object({
  access_token: z.string(),
  id_token: z.string(),
});

export type RequestCodeResponse = z.infer<typeof RequestCodeResponseSchema>;
